.dropdown-menu .fas {
  color: dimgray; }

.table.user-groups tr:first-of-type td {
  border-top: none; }

.table.user-groups tr.form-row td {
  border-top: none;
  margin-top: 20px; }

[type='radio'].updateUserRoleRadio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0; }

[type='radio'].updateUserRoleRadio + label {
  cursor: pointer;
  color: #5a5a5a; }

[type='radio'].updateUserRoleRadio:checked + label {
  font-size: 130%;
  color: black; }

.userTypeText {
  text-align: center; }

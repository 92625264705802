@mixin rotate {
  transform: rotate(15deg);
}

@mixin sidebar-icons {
  .nav-item {
    &:last-child {
      margin-bottom: 1rem;
    }
    .nav-link {
      text-align: center;
      padding: 0.75rem 1rem;
      width: $sidebar-collapsed-width;
      span {
        font-size: 0.65rem;
        display: block;
      }
    }
    .dropdown-menu {
      position: absolute !important;
      transform: none !important;
      left: calc(#{$sidebar-collapsed-width} + 0.5rem) !important;
      margin: 0;
      &.dropup {
        bottom: 0;
        top: auto !important;
      }
    }
    &.dropdown .dropdown-toggle::after {
      display: none;
    }
  }
}

.dropdown-menu .fas {
  color: dimgray; }

.custom-text.name-filter {
  vertical-align: top;
  line-height: 1.9em;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-left: 5px;
  font-size: 0.9em; }

.mapboxgl-ctrl-top-right {
  top: 0;
  left: 15px; }

.mapboxgl-control-container {
  position: absolute;
  top: 0; }

.mapbox-gl-draw_ctrl-draw-btn {
  background-color: white;
  border-radius: 3px;
  border: 1px grey solid;
  margin: 2px; }

.mapbox_style_choices {
  padding-left: 15px;
  padding-right: 15px; }

.aoiRadios {
  padding-right: 15px;
  padding-left: 5px; }

.mapThere {
  display: block; }

.mapNotThere {
  display: none; }

.mapboxgl-canvas {
  height: 600px !important;
  width: 100% !important;
  padding-left: 0;
  padding-right: 0;
  position: relative; }

#aoi-table td,
#updateSiteParamsTable td,
#updateSiteIndexTable td,
#siteGroupsTable td {
  padding: 4px !important;
  border-top: 0px solid #ffffff;
  text-align: end; }

.mapboxgl-control-container {
  height: 20px; }

.mapboxgl-ctrl-group mapboxgl-ctrl {
  width: 50px !important; }

#latlonginfo {
  display: table;
  position: absolute;
  right: 25px;
  margin: 0px auto;
  word-wrap: anywhere;
  white-space: pre-wrap;
  font-size: 15px;
  text-align: center;
  color: #8a8a8a;
  font-weight: bolder;
  z-index: 9999; }

.map-radio-imgs {
  height: 30px;
  width: 30px;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19); }

[type='radio'].mapRadios,
[type='radio'].siteTypeRadios {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0; }

/* IMAGE STYLES */
[type='radio'].mapRadios + img,
[type='radio'].siteTypeRadios + i {
  cursor: pointer; }

[type='radio']:checked + i {
  font-size: 20px; }

/* CHECKED STYLES */
[type='radio']:checked + img {
  outline: 2px solid black; }

#mapStyleMenu {
  position: absolute;
  bottom: 5px;
  left: 20px;
  border: none;
  border-radius: 3px;
  z-index: 1; }

#uploadFileButton {
  position: absolute;
  top: 85px;
  left: 28px;
  border: none;
  border-radius: 3px;
  z-index: 1;
  height: 30px;
  width: 30px;
  padding: 3px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1); }

#sitesAndSubsitesMenu {
  position: absolute;
  bottom: 5px;
  right: 40px;
  color: black;
  z-index: 9999;
  font-size: 15px; }

.subsitesHidden {
  visibility: hidden;
  height: 0px; }

.subsitesHidden {
  font-weight: bolder !important; }

#sitesAndSubsitesMenu ul li {
  list-style-type: none; }

.sitesSubsites {
  border: none;
  margin: 5px;
  border-radius: 3px;
  z-index: 1;
  height: 30px;
  width: 30px;
  padding: 3px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  opacity: 0.5; }

#parentSite {
  background-color: #FF7F50;
  border-color: #FF7F50; }

#subSite {
  background-color: #BDE0BD;
  border-color: #BDE0BD; }

#newSite {
  background-color: #3BB2D0;
  border-color: #3BB2D0; }

#searchForAOI {
  position: absolute;
  top: 120px;
  left: 28px;
  border: none;
  border-radius: 3px;
  background-color: '#F8F9FA';
  z-index: 1;
  height: 30px;
  width: 30px;
  padding: 3px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1); }

#uploadFileButton:hover,
#deleteUploadFile:hover,
#searchForAOI {
  background-color: rgba(0, 0, 0, 0.05); }

#filterRow {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0px white; }

.table thead th,
.table th {
  border-bottom: 0px white !important; }

#displaySite {
  background-color: white;
  position: absolute;
  margin: 1px 1px;
  padding: 10px 10px;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
  top: 0;
  left: 0;
  width: 250px;
  max-height: 45px;
  width: auto;
  z-index: 1; }

#displaySiteTable td {
  border: 0px white;
  padding: 0px 0px; }

tr.hide-table-padding td {
  padding: 0; }

.expand-button {
  position: relative; }

.accordion-toggle .expand-button:after {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translate(0, -50%);
  content: '-'; }

.accordion-toggle.collapsed .expand-button:after {
  content: '+'; }

.subsitesStyle {
  background-color: #efeeee;
  border: #efeeee; }

.sitesListTable tbody tr:hover td, .table tbody tr:hover th {
  background-color: #f7f7f7; }

.mapboxgl-ctrl-top-right {
  margin-left: 40px; }

.form-section-names {
  font-weight: bold;
  font-size: 110%; }

.selected-filter {
  border: black solid 2px !important;
  background: #d3d2d2 !important; }

#createSiteParamsTable th,
#createSiteParamsTable td {
  padding: 5px !important;
  border: none; }

.AOIInfoButtons {
  width: 100%; }

input[type="file"] {
  display: none; }

.custom-file-upload,
.search-aoi-button {
  border: 1px solid #f8f9fa;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  color: black;
  text-align: center;
  width: 60%; }

#fileUploadName {
  font-family: 'Courier New', Courier, monospace; }

#deleteFileUpload {
  display: none; }

#fileUploadedDiv {
  display: none; }

#deleteUploadFile {
  border: 1px solid #f8f9fa;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  color: black;
  text-align: center; }

.dropdown-menu .fas {
	color: dimgray;
}

.custom-text.name-filter {
	width: 200px;
	vertical-align: top;
	line-height: 1.9em;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	padding-left: 5px;
	font-size: 0.9em;
}
.custom-select.facet-select {
	width: 130px;
}

.table.reducedBorders {
	th,
	td {
		border-top: none;
	}
}

.dropdown-menu .fas {
	color: dimgray;
}

.table.user-groups tr:first-of-type td {
	border-top: none;
}
.table.user-groups tr.form-row td {
	border-top: none;
	margin-top: 20px;
}

// .updateUserRoleLabel {
// 	padding: 0;
// 	margin: 0;
// 	text-align: center;
// }

// [type='radio'].updateUserRoleRadio {
// 	opacity: 1;
// 	margin-left: 15px;
// 	margin-bottom: 0;
// 	margin-top: 0;
// }

[type='radio'].updateUserRoleRadio {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
	
}

[type='radio'].updateUserRoleRadio + label {
	cursor: pointer;
	color: rgb(90, 90, 90)
}

[type='radio'].updateUserRoleRadio:checked + label {
	font-size: 130%;
	color: rgb(0, 0, 0);
}

.userTypeText {
	text-align: center;
}





// Styling for custom cards
// Custom class for the background icon in card blocks
.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -1.25rem;
  right: -1rem;
  opacity: 0.4;

  font-size: 5rem;
  @include rotate;
}

// Override breakpoints for card columns to work well with sidebar layout
.card-columns {
  @media (min-width: 576px) {
    column-count: 1;
  }
  @media (min-width: 768px) {
    column-count: 2;
  }
  @media (min-width: 1200px) {
    column-count: 2;
  }
}

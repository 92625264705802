// Additional Text Helper Class
.smaller {
  font-size: 0.7rem;
}

// Helper class for the overflow property
.o-hidden {
  overflow: hidden !important;
}

// Helper classes for z-index
.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}
